<template>
  <div>
    <div v-if="page == 'first'">
      <a-radio-group
        :value="type"
        @change="(e) => (type = e.target.value)"
      >
        <a-radio :value="1">
          Upload from file
        </a-radio>
        <a-radio :value="2">
          Paste to textbox
        </a-radio>
        <a-radio :value="3">
          Specify Range
        </a-radio>
      </a-radio-group>
      <div class="mt-3">
        <div class="d-flex">
          <div style="padding: 5px">
            Action
          </div>
          <a-select
            :default-value="'add numbers'"
            class="mr-3 ant-select-custom"
            @change="getValueOfSelect"
          >
            <a-select-option :value="addNumbers">
              Add Numbers
            </a-select-option>
            <a-select-option :value="deleteNumbers">
              Delete Numbers
            </a-select-option>
          </a-select>
        </div>

        <div
          v-if="type == 1"
          class="mt-3"
        >
          <a-upload-dragger
            accept=".csv"
            :before-upload="beforeUpload"
            :multiple="false"
            @change="handleChange"
          >
            <p class="ant-upload-drag-icon">
              <inbox-outlined v-model:value="file" />
            </p>
            <p class="ant-upload-text">
              Click or drag file to this area to upload
            </p>
            <p class="ant-upload-hint">
              Support for a single or bulk upload. Strictly prohibit from uploading company data or
              other band files
            </p>
          </a-upload-dragger>

          <div class="d-flex justify-content-end mt-3">
            <button
              class="btn btn-outline-primary mr-2"
              @click="$router.go(-1)"
            >
              Cancel
            </button>
            <button
              :disabled="file == null"
              class="btn btn-success"
              @click="submitImportedFile()"
            >
              Submit
            </button>
          </div>
        </div>
        <!--        show import_file number deleted messages -->
        <div
          v-if="type == 1 && importFileDeletedError && importFileDeletedError.length > 0"
          class="d-flex flex-column text-center"
        >
          <p
            v-for="i in importFileDeletedError"
            :key="i"
            class="mt-2"
          >
            {{ `Number  ${i} deleted Successfully ` }}
          </p>
        </div>
        <div
          v-if="type == 1 && importFileCatchError && importFileCatchError.length > 0"
          class="d-flex flex-column text-center"
        >
          <p
            v-for="i in importFileCatchError"
            :key="i"
            class="mt-2"
          >
            {{ `Number  ${i.tn} not deleted, ${i.reason} ` }}
          </p>
        </div>
        <div
          v-if="type == 1 && importFileNotFullDeletedNumber && importFileNotFullDeletedNumber.length > 0"
          class="d-flex flex-column text-center"
        >
          <p
            v-for="i in importFileNotFullDeletedNumber"
            :key="i"
            class="mt-2"
          >
            {{ `Number  ${i.tn} not deleted, ${i.reason} ` }}
          </p>
        </div>
        <div
          v-if="type == 1 && importFileCreatedFailed && importFileCreatedFailed.length > 0"
          class="d-flex flex-column text-center"
        >
          <p
            v-for="i in importFileCreatedFailed"
            :key="i"
            class="mt-2"
          >
            {{ `Number  ${i.number}  is not ${selectedAction === 'add numbers' ? 'added' : 'deleted'}. Error: ${i.reason}` }}
          </p>
        </div>
        <div
          v-if="type == 2"
          class="mt-3"
        >
          <a-textarea
            v-model:value="numbers"
            :max-length="5000"
            :rows="3"
            placeholder=""
          />
          <small>
            Max of 5000 numbers at one time.<br>
            Please put one number per line
          </small>
          <div class="d-flex justify-content-end mt-3">
            <button
              :disabled="numbers == ''"
              class="btn btn-success"
              @click="submitImportedNumbers"
            >
              Submit
            </button>
          </div>
        </div>
        <div
          v-if="type == 2 && bulkCreateErrorNumber && bulkCreateErrorNumber.length > 0"
          class="d-flex flex-column text-center"
        >
          <p
            v-for="i in bulkCreateErrorNumber"
            :key="i"
            class="mt-2"
          >
            {{ `Number  ${i.number}  is not ${selectedAction === 'add numbers' ? 'added' : 'deleted'}. Error: ${i.reason}` }}
          </p>
        </div>
        <div
          v-if="type == 2 && bulkCreateError && bulkCreateError.length > 0"
          class="d-flex flex-column text-center"
        >
          <p
            v-for="i in bulkCreateError"
            :key="i"
            class="mt-2"
          >
            {{ `Number  ${i.number}  is not ${selectedAction === 'add numbers' ? 'added' : 'deleted'}. Error: ${i.reason}` }}
          </p>
        </div>
        <div
          v-if="bulkDeleteItems && bulkDeleteItems.length > 0"
          class="d-flex flex-column text-center"
        >
          <p
            v-for="i in bulkDeleteItems"
            :key="i"
            class="mt-2"
          >
            {{ "Number " + i + " is Deleted Successfully." }}
          </p>
        </div>
        <div
          v-if="type == 2 && bulkDeleteError && bulkDeleteError.length > 0"
          class="d-flex flex-column text-center"
        >
          <p
            v-for="i in bulkDeleteError"
            :key="i"
            class="mt-2"
          >
            {{ `Number  ${i.number}  is not ${selectedAction === 'add numbers' ? 'added' : 'deleted'}. Error: ${i.reason}` }}
          </p>
        </div>
        <div
          v-if="type == 2 && bulkCatchError && bulkCatchError.length > 0"
          class="d-flex flex-column text-center"
        >
          <p
            v-for="i in bulkCatchError"
            :key="i"
            class="mt-2"
          >
            {{ `Number  ${i.number}  is not ${selectedAction === 'add numbers' ? 'added' : 'deleted'}. Error: ${i.reason}` }}
          </p>
        </div>
        <div
          v-if="type == 3"
          class="mt-3"
        >
          <a-form class="d-flex flex-column">
            <a-row :gutter="24">
              <a-col :span="12">
                <a-form-item
                  label="From"
                  class="w-100 d-block"
                >
                  <a-input
                    v-model:value="numbersFrom"
                    type="number"
                    class="w-100"
                  />
                </a-form-item>
              </a-col>
              <a-col :span="12">
                <a-form-item
                  label="To"
                  class="w-100 d-block"
                >
                  <a-input
                    v-model:value="numbersTo"
                    type="number"
                    class="w-100"
                  />
                </a-form-item>
              </a-col>
            </a-row>

            <a-button
              :disabled="numbersFrom == '' || numbersTo == ''"
              class="btn btn-success ml-auto"
              @click="submitNumbersFromTo"
            >
              Submit
            </a-button>
          </a-form>
        </div>

        <div
          v-if="type == 3 && rangeCreateNumberError && rangeCreateNumberError.length > 0"
          class="d-flex flex-column text-center"
        >
          <p
            v-for="i in rangeCreateNumberError"
            :key="i"
            class="mt-2"
          >
            {{ `Number  ${i.number}  is not ${selectedAction === 'add numbers' ? 'added' : 'deleted'}. Error: ${i.reason}` }}
          </p>
        </div>


        <div
          v-if="type == '3' && rangeDeleteItems && rangeDeleteItems.length > 0"
          class="d-flex flex-column text-center"
        >
          <p
            v-for="i in rangeDeleteItems"
            :key="i"
            class="mt-2"
          >
            {{ `Number  ${i}  is Deleted Successfully.` }}
          </p>
        </div>
        <div
          v-if="type == 3 && rangeDelete && rangeDelete.length > 0"
          class="d-flex flex-column text-center"
        >
          <p
            v-for="i in rangeDelete"
            :key="i"
            class="mt-2"
          >
            {{ `Number  ${i.tn}  is not ${selectedAction === 'add numbers' ? 'added' : 'deleted'}.Error: ${i.reason}` }}
          </p>
        </div>
      </div>
    </div>

    <div v-if="page == 'valid'">
      <div class="table-responsive">
        <table class="table table-hover text-nowrap">
          <thead>
            <tr>
              <th>TN</th>
              <th>OCN</th>
              <th>LATA</th>
              <th>State</th>
              <th>Rate Center</th>
              <th>Carrier</th>
            </tr>
          </thead>
          <tbody v-if="allData">
            <tr
              v-for="i in allData"
              :key="i"
              class="position-relative"
            >
              <td>{{ i.tn || i.number }}</td>
              <td v-if="i.ocn">
                {{ i.ocn }}
              </td>
              <td v-if="i.lata">
                {{ i.lata }}
              </td>
              <td v-if="i.state">
                {{ i.state }}
              </td>
              <td v-if="i.rate_center">
                {{ i.rate_center }}
              </td>
              <td v-if="i.carrier">
                {{ i.carrier }}
              </td>
              <td
                v-else-if="i.reason"
                class="position-absolute"
              >
                Invalid. {{ i.reason }}
              </td>
              <td v-if="i.reason" />
              <td v-if="i.reason" />
              <td v-if="i.reason" />
              <td v-if="i.reason" />
            </tr>
          </tbody>
        </table>
      </div>
      <a-pagination
        v-if="total"
        :default-current="1"
        :total="total"
        @change="pagination($event)"
      />
      <div class="d-flex justify-content-end">
        <button
          class="btn btn-success"
          @click="done"
        >
          Done
        </button>
      </div>
    </div>
    <div v-if="page == 'reporting'">
      <span class="font-size-18">Your numbers are being imported now...</span>

      <div class="flex-grow-1 mt-2">
        <div class="text-dark font-size-14 text-nowrap mb-1 ml-auto">
          {{ progressPercent.toFixed(0) }}% from {{ tnTotal }}
        </div>
        <div class="progress">
          <div
            class="progress-bar bg-success"
            :style="{width: progressPercent+'%'}"
          />
        </div>

        <button
          class="btn btn-success ml-auto my-3 d-flex justify-content-center"
          @click="page = 'valid'"
        >
          Next
        </button>

        <div class="mt-5 text-center w-100">
          <div v-if="created">
            <p
              v-for="i in created"
              :key="i"
              class="mt-2"
            >
              {{ "Number " + i.tn + " is added successfully." }}
            </p>
          </div>
          <div v-if="failed">
            <p
              v-for="i in failed"
              :key="i"
              class="mt-2"
            >
              {{ "Number " + i.number + " is not added. Error: " + i.reason }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '@/axios'
import {Modal, notification} from 'ant-design-vue'

export default {
  data() {
    return {
      type: 1,
      page: 'first',
      numbers: '',
      file: null,
      item: {
        file: null,
      },
      addNumbers: 'add numbers',
      deleteNumbers: 'delete numbers',
      importFileDeletedError: [],
      importFileNotFullDeletedNumber: [],
      importFileCatchError: [],
      importFileCreatedFailed: [],
      bulkCreateErrorNumber: [],
      bulkCreateError: [],
      bulkDeleteError: [],
      bulkDeleteItems: [],
      bulkCatchError: [],
      rangeCreateNumberError: [],
      rangeDelete: [],
      rangeDeleteItems: [],
      errorMessage: '',
      numbersFrom: '',
      numbersTo: '',
      progressPercent: 0,
      tnTotal: 0,
      created: null,
      failed: null,
      allData: null,
      selectedAction: 'add numbers',
    }
  },
  computed: {
    tnType() {
      if (this.$route.path === '/authentication/tn/import') {
        return 'authentication'
      } else return 'signing'
    },
  },
  watch: {},
  methods: {
    getValueOfSelect(value) {
      this.selectedAction = value
    },
    submitImportedNumbers() {
      var that = this;
      const config = {
        onUploadProgress: event => {
          const {loaded, total} = event;
          this.progressPercent = Math.round(
              (event.loaded * 100) / event.total
          );
          that.page = "reporting"
        },
      }
      Modal.confirm({
        title: 'Do you Want to Submit?',
        onOk() {
          if(that.selectedAction  === 'add numbers'){
            const params = that.numbers.split("\n").filter(item => item).map(item => (+item))
            axios
                .post(`/${that.tnType}/tn/bulk_create`, params, config)
                .then((res) => {
                  that.bulkCreateErrorNumber = res.data.failed
                  if (res.data.created.length === 0) {
                    notification.error({
                      message:  'Failed, try again',
                    })
                  } else {
                     notification.success({
                      message: 'Submitted successfully'
                    })
                  }
                  if (res.data.total === 0) {
                    that.page = "first"
                  }
                  that.progressData(res)
                })
                .catch((err) => {
                  that.bulkCreateError = err.response.data.failed
                  that.page = "first"
                  notification.error({
                    message: err.response ? err.response.data.message : 'Failed, try again',
                  })
                });
            that.numbers = ''
          }else  if(that.selectedAction  === 'delete numbers') {
            const number  = that.numbers.replace(/^\s+|\s+$/g,'').split(/\s+/).map(el => +el)
            axios
                .post(`/${that.tnType}/tn/bulk_delete`, number)
                .then((res) => {
                  that.bulkDeleteError =  res.data.failed
                  that.bulkDeleteItems =  res.data.deleted
                  setTimeout(() => {
                    if(that.tnType === 'authentication') {
                      that.$router.push({name: 'auth-tn'})
                    }else {
                      that.$router.push({name: 'sign-tn'})
                    }
                  },5000)
                  notification.success({
                    message: 'Deleted successfully'
                  })
                })
                .catch((err) => {
                  that.bulkCatchError = err.response.data.failed
                  notification.error({
                    message: 'Failed, try again',
                  })
                  that.numbers = ''
                });
            }
        },
        onCancel() {},
      })
    },

    submitNumbersFromTo() {
      var that = this;

      const config = {
        onUploadProgress: event => {
          const {loaded, total} = event;
          this.progressPercent = Math.round(
              (event.loaded * 100) / event.total
          );
          that.page = "reporting"
        },
      }

      Modal.confirm({
        title: 'Do you Want to Submit?',
        onOk() {
          if(that.selectedAction === 'add numbers') {
            const params = {
              startRange: that.numbersFrom,
              endRange: that.numbersTo
            };
            axios
                .post(`/${that.tnType}/tn/range_create`, params, config)
                .then((res) => {
                  that.rangeCreateError = res.data.failed
                  if (res.data.created.length === 0) {
                    notification.error({
                      message: 'Failed, try again',
                    })
                  } else {
                    notification.success({
                      message: 'Submitted successfully'
                    })
                  }
                  if (res.data.total === 0) {
                    that.page = "first"
                  }
                  that.progressData(res)
                })
                .catch((err) => {
                  that.page = "first"
                  that.rangeCreateNumberError = err.response.data.failed
                  notification.error({
                    message: err.response ? err.response.data.message : 'Failed, try again',
                  })
                });
            that.numbersFrom = ''
            that.numbersTo = ''
          }else if(that.selectedAction === 'delete numbers') {
            const params = {
              startRange: that.numbersFrom,
              endRange: that.numbersTo
            };
            axios
                .post(`/${that.tnType}/tn/range_delete`, params)
                .then((res) => {
                  if (res.data.total === 0) {
                    that.page = "first"
                  }
                  that.rangeDeleteItems = res.data.deleted
                  that.rangeDelete = res.data.failed
                  setTimeout(() => {
                    if(that.tnType === 'authentication') {
                      that.$router.push({name: 'auth-tn'})
                    }else {
                      that.$router.push({name: 'sign-tn'})
                    }
                  },5000)
                  notification.success({
                    message: 'Range Deleted Successfully',
                  })
                }).catch((err) => {
                  that.page = "first"
                  that.rangeDelete = err.response.data.failed
                  notification.error({
                    message: that.errorMessage ? that.errorMessage : 'Failed, try again',
                  })
                });
            that.numbersFrom = ''
            that.numbersTo = ''
          }

        },
        onCancel() {},
      });
    },

    progressData(res) {
      this.created = res.data.created;
      this.failed = res.data.failed;
      this.allData = this.created.concat(this.failed)
      // this.progressPercent = (res.data.created.length * 100) / res.data.total
      this.tnTotal = res.data.total
    },

    submitImportedFile() {
      if(this.selectedAction === 'add numbers'){
        const config = {
          onUploadProgress: event => {
            const {loaded, total} = event;
            this.progressPercent = Math.round(
                (event.loaded * 100) / event.total
            );
            this.page = "reporting"
          },
        }
        const file = this.file;
        const formData = new FormData();
        formData.append('file', file);
        axios
            .post(`/${this.tnType}/tn/import_file`, formData, config)
            .then((res) => {
              this.progressData(res)
              if (res.data.total === 0) {
                this.page = "first"
              }
            })
            .catch((err) => {
              this.page = "first"
              this.importFileCreatedFailed = err.response.data.failed
              notification.error({
                message: err.response.data.message ? err.response.data.message : 'Failed, try again'
              })
            })
      }else if(this.selectedAction === 'delete numbers') {
        const file = this.file;
        const formData = new FormData();
        formData.append('file', file);
        axios
            .post(`/${this.tnType}/tn/import_file_delete`, formData)
            .then((res) => {
              this.importFileDeletedError = res.data.deleted
              this.importFileNotFullDeletedNumber = res.data.failed
              setTimeout(() => {
                if(this.tnType === 'authentication') {
                  this.$router.push({name: 'auth-tn'})
                }else {
                  this.$router.push({name: 'sign-tn'})
                }
              },5000)
            })
            .catch((err) => {
              this.page = "first"
              this.importFileCatchError = err.response.data.failed
              notification.error({
                message: err.response.message ? err.response.message : 'Failed, try again'
              })
            })
      }

    },
    handleChange(info) {
        this.file = info.file
    },
    done() {
      notification.success({
        message: 'Submitted successfully'
      })

      this.$router.push(`/${this.tnType}/tn`)
    },
    beforeUpload(f) {
      return false
    }
  },
}
</script>

<style>
.ant-upload-list-item-name {
  color: #00a45b!important;
}
.anticon-paper-clip {
  color: #00a45b!important;
}
@media (max-width: 778px) {
  .ant-select-custom{
    width: 200px!important;
  }
}
</style>